<template>
  <div>
    <v-datetime-picker
      label="Start"
      :min="new Date().toISOString().substr(0, 10)"
      timeFormat="h:mm a"
      v-model="start"
    >
      <v-icon slot="dateIcon">fa-calendar</v-icon>
      <v-icon slot="timeIcon">fa-clock</v-icon>
    </v-datetime-picker>
    <v-select
      :items="['Yearly', 'Monthly', 'Weekly', 'Daily']"
      label="Repeat"
      v-model="repeat"
    ></v-select>
    <div v-show="repeat == 'Yearly'">
      <v-container dense class="ma-0 pa-0">
        <v-row dense class="ma-0 pa-0">
          <v-col cols="3">
            <v-switch @change="(v) => (sw1 = !v)" v-model="sw0"></v-switch>
          </v-col>
          <v-col cols="5">
            <v-select
              :items="[
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ]"
              label="On Month"
              v-model="month"
              :disabled="!sw0"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              :items="Array.from({ length: 30 }, (x, i) => i + 1)"
              label="On Day"
              v-model="day"
              :disabled="!sw0"
            ></v-select>
          </v-col>
        </v-row>
        <v-row dense class="ma-0 pa-0">
          <v-col cols="3">
            <v-switch @change="(v) => (sw0 = !v)" v-model="sw1"></v-switch>
          </v-col>
          <v-col cols="3">
            <v-select
              :items="['First', 'Second', 'Third', 'Fourth', 'Last']"
              v-model="week"
              :disabled="!sw1"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              :items="[
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Firday',
                'Saturday',
                'Sunday',
                'Day',
                'Weekend',
                'Weekend Day',
              ]"
              v-model="dayOfWk"
              :disabled="!sw1"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              :items="[
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ]"
              label="On Month"
              v-model="month"
              :disabled="!sw1"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-show="repeat == 'Monthly'">
      <v-text-field
        v-model="monthlyEvery"
        label="Every month(s)"
        type="number"
      ></v-text-field>
      <v-select
        :items="Array.from({ length: 30 }, (x, i) => i + 1)"
        label="On Day"
        v-model="day"
      ></v-select>
      <v-container>
        <v-row>
          <v-col cols="3">
            <v-switch @change="(v) => (sw1 = !v)" v-model="sw0"></v-switch>
          </v-col>
          <v-col cols="9">
            <v-select
              :items="Array.from({ length: 30 }, (x, i) => i + 1)"
              label="On Day"
              v-model="day"
              :disabled="!sw0"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-switch @change="(v) => (sw0 = !v)" v-model="sw1"></v-switch>
          </v-col>
          <v-col cols="4">
            <v-select
              :items="['First', 'Second', 'Third', 'Fourth', 'Last']"
              v-model="week"
              :disabled="!sw1"
            ></v-select>
          </v-col>
          <v-col cols="5">
            <v-select
              :items="[
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Firday',
                'Saturday',
                'Sunday',
                'Day',
                'Weekend',
                'Weekend Day',
              ]"
              v-model="dayOfWk"
              :disabled="!sw1"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-show="repeat == 'Weekly'" class="mb-6">
      <v-item-group
        multiple
        style="display: flex; justify-content: space-between"
      >
        <div v-for="(n, i) in ['S', 'M', 'T', 'W', 'R', 'F', 'S']" :key="n + i">
          <v-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? 'green darken-3' : 'brown lighten-1'"
              class="d-flex align-center"
              dark
              background-color="#784f2b"
              width="40"
              height="35"
              @click="toggle"
            >
              <v-scroll-y-transition>
                <div @click="weekday(i)" class="flex-grow-1 text-center">
                  {{ n }}
                </div>
              </v-scroll-y-transition>
            </v-card>
          </v-item>
        </div>
      </v-item-group>
       <v-container  class="ma-0 pa-0 mt-3">
          <v-row class="ma-0 pa-0">
            <v-col cols="6">
              <v-menu
                ref="beginMenu"
                v-model="beginView"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="begin"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="begin"
                    label="Begin"
                    prepend-icon="fa-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="beginView"
                  v-model="begin"
                  full-width
                  @click:minute="$refs.beginMenu.save(begin)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="untilMenu"
                v-model="untilView"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="until"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="until"
                    label="Until"
                    prepend-icon="fa-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="untilView"
                  v-model="until"
                  full-width
                  @click:minute="$refs.untilMenu.save(until)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
    </div>
    <div v-show="repeat == 'Daily'">
      <v-text-field
        v-model="dailyEvery"
        label="Every number of day(s)"
        type="number"
      ></v-text-field>
    </div>
    <v-layout>
      <v-row>
        <v-col cols="4">
          <v-select
            :items="['Never', 'After', 'On Date']"
            label="End"
            v-model="end"
          ></v-select>
        </v-col>
        <v-col cols="8">
          <v-text-field
            v-show="end == 'After'"
            v-model="endExecutions"
            label="Execution(s)"
            type="number"
          ></v-text-field>
          <div v-show="end == 'On Date'">
            <v-datetime-picker
              label="End Date"
              :min="new Date().toISOString().substr(0, 10)"
              timeFormat="h:mm a"
              v-model="endDate"
            >
              <v-icon slot="dateIcon">fa-calendar</v-icon>
              <v-icon slot="timeIcon">fa-clock</v-icon>
            </v-datetime-picker>
          </div>
        </v-col>
      </v-row>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: ["modified"],
  data() {
    return {
      start: new Date(),
      until: null,
      begin: null,
      end: "Never",
      endDate: new Date(),
      endExecutions: 1,
      repeat: "Weekly",
      day: 1,
      month: "Jan",
      dayOfWk: "Monday",
      week: "First",
      weekdays: [0, 0, 0, 0, 0, 0, 0],
      monthlyEvery: 1,
      dailyEvery: 1,
      sw0: true,
      sw1: false,
      untilView:false,
      beginView:false,
    };
  },
  watch: {
    modified: function () {},
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    weekday(dayOfWeek) {
      this.weekdays[dayOfWeek] = !this.weekdays[dayOfWeek];
    },
  },
};
</script>

<style scoped>
</style>