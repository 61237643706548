<template>
  <div style="display: flex; flex-direction: column; height: 100%">
    <Status></Status>
    <v-btn
      absolute
      dark
      fab
      right
      color="green darken-2"
      style="bottom: 3%"
      @click="next()"
    >
      <v-icon>fa-chevron-right</v-icon>
    </v-btn>
    <v-btn
      color="brown"
      absolute
      dark
      fab
      left
      style="bottom: 3%"
      @click="quit()"
    >
      <v-icon>fa-ban</v-icon>
    </v-btn>
    <v-stepper dense alt-labels v-model="step" class="mt-5" non-linear>
      <v-stepper-header>
        <v-stepper-step editable step="1" @click="setStep(1)" color="brown"
          >Contact</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step editable step="2" @click="setStep(2)" color="brown"
          >Combination</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step editable step="3" @click="setStep(3)" color="brown"
          >Dates</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step editable step="4" @click="setStep(4)" color="brown"
          >Save Changes</v-stepper-step
        >
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card elevation="0" class="mb-12" height="200px">
            <div class="mb-5">
              <v-text-field
                autofocus
                label="Name"
                v-model="name"
              ></v-text-field>
            </div>
            <div>
              <v-text-field label="Phone" v-model="phone"></v-text-field>
            </div>
            <div>
              <v-text-field label="Email" v-model="email"></v-text-field>
            </div>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-items>
        <v-stepper-content step="2">
          <v-card elevation="0" class="mb-12" height="200px">
            <v-container>
              <v-row>
                <v-col cols="3">
                  <v-switch v-model="sw1"></v-switch>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="combo"
                    type="tel"
                    label="Combination"
                    class="input-group--focused"
                    maxlength="9"
                    :disabled="!sw1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-switch v-model="sw2"></v-switch>
                </v-col>
                <v-col cols="9" style="display: flex; align-items: center">
                  <span :class="{ 'text--disabled': !sw2 }"
                    >Use one time pass code</span
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-items>
        <v-stepper-content step="3">
          <v-card elevation="0" class="mb-12" height="400px">
            <RRule></RRule>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-items>
        <v-stepper-content step="4">
          <v-card
            elevation="0"
            class="mb-12 pt-12"
            height="200px"
            align="center"
          >
            <v-btn @click="save()" dark color="green darken-2"
              >Save Changes</v-btn
            >
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import Status from "@/components/Status.vue";
import RRule from "@/components/RRule.vue";
import User from "../model/User";
import Code from "../model/Code";
import CMD from "../lib/cmd";
import { v4 as UUID } from "uuid";
export default {
  name: "Invite",
  components: {
    Status,
    RRule,
  },
  created() {
    this.$emit("hideMenu");
  },
  data() {
    return {
      name: "Michael Aumock",
      email: "mikeaumock@gmail.com",
      phone: "720-720-9674",
      combo: "1234",
      step: 1,
      sw1: true,
      sw2: false,
      picker: new Date().toISOString().substr(0, 10),
    };
  },
  methods: {
    setStep(id) {
      this.step = id;
    },
    quit() {
      this.$router.push({
        name: "People",
      });
    },
    next() {
      this.step++;
      if (this.step > 4) {
        this.step = 1;
      }
    },
    async save() {
      console.log(new Date().toUTCString());
      let p = new User();
      p.id = UUID();
      p.name = this.name;
      p.phone = this.phone;
      p.email = this.email;
      p.code = new Code();
      p.code.combo = this.combo;
      try {
        await this.$store.connect();
        if (this.$store.connected) {
          let cmd = CMD.CMD_CODE_CREATE + p.code.toString();
          let answer = await this.$store.connection.write(cmd);
          if (answer.toUpperCase() == "ERR") {
            this.$emit("alert", "Error saving data to the device.", "error");
          } else {
            this.$store.db.collection("users").add(p);
            this.$emit("alert", "Data saved successfully.", "success");
            this.$router.push({ name: "Unlock" });
          }
        } else {
          this.$emit("alert", "Trouble connecting to the device.", "error");
        }
      } catch (e) {
        console.log(e);
        this.$emit("alert", "Exception with application.", "error");
      }
      // this.$store.collection("people").add(p);
      // this.$router.push({ name: "Unlock" });
    },
  },
};
</script>

<style scoped>
.v-input__icon {
  font-size: 20px;
}
.v-stepper,
.v-stepper__header {
  box-shadow: none;
}
.v-stepper__step {
  padding: 0;
  padding-top: 0.5em;
}
</style>