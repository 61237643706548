// import Roles from "./Roles";

import Entity from './Entity';

export default class extends Entity {
    phone = "";
    email = "";
    groups = []; // list of groups 
    devices = {}; //map of permissions [DEVICE_ID] = ROLE
    organizations = {}; //map of organizations [ORG_ID] = ROLE
}