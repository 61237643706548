export default {
    CMD_SESSION_SET_ROLE: '0',

    CMD_OTP_OPEN: '1',
    CMD_CODE_OPEN: '2',
    CMD_SRV_OPEN: 'd',
    CMD_CODE_LIST: '3',
    CMD_CODE_CREATE: '4',
    CMD_CODE_READ: '5',
    CMD_CODE_UPDATE: '6',
    CMD_CODE_DELETE: '7',

    CMD_EVENTS_LIST: '8',
    CMD_EVENTS_CLEAR: '9',

    CMD_TIME_NOW: 'a',
    CMD_TIME_SET: 'b',

    CMD_DISK_STATS: 'c',

    ERR_NONE: 0,
    ERR_NOT_AUTHORIZED: 1,
    ERR_NO_INFORMATION: 2,
    ERR_FS_EXISTS: 3,
    ERR_FS_DISK_FULL: 4,
    ERR_FS_NOT_FOUND: 5,

    EVT_OPEN: 1,
    EVT_COMBO_FAILED: 2,
    EVT_CODE_SAVED: 3,
    EVT_CODE_UPDATED: 4,
    EVT_TIME_UPDATED: 5,
    EVT_DISK_ERROR: 6,
    EVT_BATT_ERROR: 7,
    EVT_BATT_WARN: 8,
}