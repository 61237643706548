import Entity from "./Entity";

export default class extends Entity {
    targetDeviceID = ""; //lockbox this code belongs to
    targetUserID = ""; //user this code belongs to
    otp = "0";
    combo = "";        //combination code 9 digits or less. Otherwise digit 10 is 4 or less.
    has_schedule = false;  //if calendar should be applied to the code
    all_day = false;       // bool for all day
    time_start = 0;   // in seconds
    time_end = 0;     // in seconds
    series_start = 0; // Yes, 2038 will be magical
    series_end = 0;   // in seconds
    rrule = "";
    color = '#4CAF50';
    description = 'code';

    toString() {
        let out = '';
        if (this.id > 0) {
            out += `${this.id}|`;
        }
        if (this.otp.length < 6) {
            this.otp = "0";
        }
        out += `${this.otp}|${this.combo}|${(this.has_schedule ? 1 : 0)}`;
        if (this.has_schedule) {
            out += `${this.all_day}|${this.time_start}|${this.time_end}|${this.series_start}|${this.series_end}|${this.rrule}`;
        }
        return out;
    }
}